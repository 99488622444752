<template>
  <div id="bd">
    <div id="ban-in">
      <div class="ban-bg"></div>
      <div class="banner-box wp">
        <h3>产品服务</h3>
        <h5>Product Service</h5>
      </div>
    </div>

    <!-- 我们的产品 -->
    <div class="myProducts">
      <div class="wp">
        <div class="tit-i">
          <h3>我们的产品</h3>
          <h5>
            Our
            <span>Products</span>
          </h5>
        </div>
        <div class="myProducts-card">
          <div class="myProducts-card-item">
            <i class="t">&nbsp;</i><i class="r">&nbsp;</i><i class="b">&nbsp;</i
            ><i class="l">&nbsp;</i>
            <img
              src="../../assets/front/product/pd1.png"
              width="52"
              height="52"
            />
            <div class="myProducts-card-item-content">
              <h2>开发区数字化转型</h2>
              <ul>
                <li>
                  <i></i>
                  <div class="li-text">管理数字化</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">服务数字化</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">监管数字化</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">产业数字化</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="myProducts-card-item">
            <i class="t">&nbsp;</i><i class="r">&nbsp;</i><i class="b">&nbsp;</i
            ><i class="l">&nbsp;</i>
            <img
              src="../../assets/front/product/pd2.png"
              width="52"
              height="52"
            />
            <div class="myProducts-card-item-content">
              <h2>工业高质量发展</h2>
              <ul>
                <li style="width: 100%">
                  <i></i>
                  <div class="li-text">企业亩产效益评价</div>
                </li>
                <li style="width: 100%">
                  <i></i>
                  <div class="li-text">智慧工业管理</div>
                </li>
                <li style="width: 100%">
                  <i></i>
                  <div class="li-text">大数据精准招商</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="myProducts-card-item">
            <i class="t">&nbsp;</i><i class="r">&nbsp;</i><i class="b">&nbsp;</i
            ><i class="l">&nbsp;</i>
            <img
              src="../../assets/front/product/pd3.png"
              width="52"
              height="52"
            />
            <div class="myProducts-card-item-content">
              <h2>节能减排（双碳）</h2>
              <ul>
                <li style="width: 100%">
                  <i></i>
                  <div class="li-text">工业能耗监测</div>
                </li>
                <li style="width: 100%">
                  <i></i>
                  <div class="li-text">工业“双碳”数字化</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="myProducts-card-item">
            <i class="t">&nbsp;</i><i class="r">&nbsp;</i><i class="b">&nbsp;</i
            ><i class="l">&nbsp;</i>
            <img
              src="../../assets/front/product/pd4.png"
              width="52"
              height="52"
            />
            <div class="myProducts-card-item-content">
              <h2>化工园区智能化管控</h2>
              <ul>
                <li>
                  <i></i>
                  <div class="li-text">园区安全风险</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">环保监测</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">敏捷应急</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">封闭管理</div>
                </li>
                <li style="width: 100%">
                  <i></i>
                  <div class="li-text">危化品安全风险</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="myProducts-card-item">
            <i class="t">&nbsp;</i><i class="r">&nbsp;</i><i class="b">&nbsp;</i
            ><i class="l">&nbsp;</i>
            <img
              src="../../assets/front/product/pd5.png"
              width="52"
              height="52"
            />
            <div class="myProducts-card-item-content">
              <h2>优化营商环境</h2>
              <ul>
                <li>
                  <i></i>
                  <div class="li-text">评估监测</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">智慧政策</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">政企互动</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">金融服务</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="myProducts-card-item">
            <i class="t">&nbsp;</i><i class="r">&nbsp;</i><i class="b">&nbsp;</i
            ><i class="l">&nbsp;</i>
            <img
              src="../../assets/front/product/pd6.png"
              width="52"
              height="52"
            />
            <div class="myProducts-card-item-content">
              <h2>科技成果转化</h2>
              <ul>
                <li>
                  <i></i>
                  <div class="li-text">成果对接</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">企业创新</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">科技政策</div>
                </li>
                <li>
                  <i></i>
                  <div class="li-text">专家资源</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 客户细分 -->
    <div class="customer">
      <div class="wp">
        <div class="tit-i">
          <h3>客户细分</h3>
          <h5>
            Customer
            <span>Classification</span>
          </h5>
        </div>
        <div class="businesss-card">
          <div class="businesss-card-item">
            <div class="businesss-card-item-img">
              <img src="../../assets/front/product/cc1.png" />
            </div>
            <div class="businesss-card-item-title">
              <span>工业主管部门</span>
            </div>
            <div class="businesss-card-item-mask">
              <div class="businesss-card-item-mask-title">工业主管部门</div>
              <div class="businesss-card-item-mask-bk"></div>
              <div class="businesss-card-item-mask-content">
                <p>智慧工业管理</p>
                <p>智慧园区运营</p>
                <p>智慧产业决策</p>
                <p>工业数字孪生</p>
              </div>
            </div>
          </div>
          <div class="businesss-card-item">
            <div class="businesss-card-item-img">
              <img src="../../assets/front/product/cc2.png" />
            </div>
            <div class="businesss-card-item-title">
              <span>工业企业</span>
            </div>
            <div class="businesss-card-item-mask">
              <div class="businesss-card-item-mask-title">工业企业</div>
              <div class="businesss-card-item-mask-bk"></div>
              <div class="businesss-card-item-mask-content">
                <p>免费平台服务</p>
                <p>有偿增值服务</p>
              </div>
            </div>
          </div>
          <div class="businesss-card-item">
            <div class="businesss-card-item-img">
              <img src="../../assets/front/product/cc3.png" />
            </div>
            <div class="businesss-card-item-title">
              <span>工业从业者</span>
            </div>
            <div class="businesss-card-item-mask">
              <div class="businesss-card-item-mask-title">工业从业者</div>
              <div class="businesss-card-item-mask-bk"></div>
              <div class="businesss-card-item-mask-content">
                <p>工业从业人员服务平台</p>
                <p>公益电商平台</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 业务定位 -->
    <div class="projectPosition">
      <div class="wp">
        <div class="tit-i">
          <h3>业务定位</h3>
          <h5>拿数据说话，让数据决策，用数据服务</h5>
        </div>
        <div class="projectPosition-box">
          <div class="projectPosition-box-item">
            <div class="projectPosition-box-card">
              <img
                src="../../assets/front/product/pp1.png"
                width="50"
                height="50"
              />
              <div class="projectPosition-box-card-content">
                <p>政府</p>
                <h1>工业管理，产业指导</h1>
                <h2>G2M 政府-制造业</h2>
                <ul>
                  <li>
                    <i></i>
                    <div class="li-text">面向企业的高效政务服务</div>
                  </li>
                  <li>
                    <i></i>
                    <div class="li-text">O2O政务服务</div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="projectPosition-box-card">
              <img
                src="../../assets/front/product/pp2.png"
                width="50"
                height="50"
              />
              <div class="projectPosition-box-card-content">
                <p>消费</p>
                <h1>消费定制，产业培育</h1>
                <h2>C2M 消费-制造业</h2>
                <ul>
                  <li>
                    <i></i>
                    <div class="li-text">制造业产品直卖</div>
                  </li>
                  <li>
                    <i></i>
                    <div class="li-text">按需JIT精益制造</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="projectPosition-box-center">
            <img src="../../assets/front/product/ppc.png" />
          </div>
          <div class="projectPosition-box-item">
            <div class="projectPosition-box-card">
              <img
                src="../../assets/front/product/pp3.png"
                width="50"
                height="50"
              />
              <div class="projectPosition-box-card-content">
                <p>服务</p>
                <h1>企业服务，产业配套</h1>
                <h2>S2M 服务-制造业</h2>
                <ul>
                  <li>
                    <i></i>
                    <div class="li-text">共享产业配套服务</div>
                  </li>
                  <li>
                    <i></i>
                    <div class="li-text">共享企业服务资源</div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="projectPosition-box-card">
              <img
                src="../../assets/front/product/pp4.png"
                width="50"
                height="50"
              />
              <div class="projectPosition-box-card-content">
                <p>制造</p>
                <h1>上下游对接，产业运营</h1>
                <h2>M2M 制造业-制造业</h2>
                <ul>
                  <li>
                    <i></i>
                    <div class="li-text">上下游供应链协作</div>
                  </li>
                  <li>
                    <i></i>
                    <div class="li-text">有偿共享思维盘活沉默资源</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 服务体系 -->
    <div class="serviceSystem">
      <div class="wp">
        <div class="tit-i">
          <h3>服务体系</h3>
          <h5>
            Service
            <span>System</span>
          </h5>
        </div>
        <div class="serviceSystem-box">
          <img src="../../assets/front/product/tt1.png" />
          <img
            src="../../assets/front/product/tt2.png"
            style="margin-bottom: 260px"
          />
          <div
            class="serviceSystem-box-dot dot1 dot-ani1"
            style="position: absolute; top: 0; left: 0"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>数字经济</p>
            <p>发展</p>
          </div>
          <div
            class="serviceSystem-box-dot dot2 dot-ani1"
            style="position: absolute; top: 0; right: 70px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>产业</p>
            <p>服务</p>
          </div>
          <div
            class="serviceSystem-box-dot dot3 dot-ani2"
            style="position: absolute; top: 50px; left: 240px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>工业</p>
            <p>管理</p>
          </div>
          <div
            class="serviceSystem-box-dot dot2 dot-ani2"
            style="position: absolute; top: 50px; left: 400px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>企业</p>
            <p>服务</p>
          </div>
          <div
            class="serviceSystem-box-dot dot2 dot-ani2"
            style="position: absolute; top: 50px; right: 350px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>营商环境</p>
            <p>优化</p>
          </div>
          <div
            class="serviceSystem-box-dot dot3 dot-ani3"
            style="position: absolute; top: 170px; left: 530px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>环保</p>
            <p>监测</p>
          </div>
          <div
            class="serviceSystem-box-dot dot3 dot-ani4"
            style="position: absolute; top: 200px; right: 230px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>化工</p>
            <p>园区</p>
          </div>
          <div
            class="serviceSystem-box-dot dot2 dot-ani5"
            style="position: absolute; top: 220px; left: 300px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>项目</p>
            <p>申报</p>
          </div>
          <div
            class="serviceSystem-box-dot dot3 dot-ani6"
            style="position: absolute; top: 250px; right: 400px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>双碳</p>
          </div>
          <div
            class="serviceSystem-box-dot dot4 dot-ani7"
            style="position: absolute; top: 300px; right: 530px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>亩产效益</p>
            <p>大数据</p>
            <p>平台</p>
          </div>
          <div
            class="serviceSystem-box-dot orgdot dot5 dot-ani8"
            style="position: absolute; bottom: 130px; left: 380px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>驻点运营</p>
            <p>服务</p>
          </div>
          <div
            class="serviceSystem-box-dot orgdot dot1 dot-ani8"
            style="position: absolute; bottom: 130px; right: 430px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>亩产效益</p>
            <p>评价</p>
          </div>
          <div
            class="serviceSystem-box-dot orgdot dot3 dot-ani9"
            style="position: absolute; bottom: 10px; right: 540px"
          >
            <div class="serviceSystem-box-dot-pop">
              描述文字描述文字描述文字描述 文字描述文字描述文字
            </div>
            <p>金融</p>
            <p>服务</p>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="ourproducts">-->
    <!--      <div class="wp">-->
    <!--        <div class="op-left">-->
    <!--          <div class="moduleTitleLeft">-->
    <!--            <h3>我们的产品</h3>-->
    <!--            <h5>-->
    <!--              OUR-->
    <!--              <span>PRODUCTS</span>-->
    <!--            </h5>-->
    <!--          </div>-->
    <!--          <div class="opl-intro">-->
    <!--            采用云计算、物联网、移动互联网等新一代信息技术，以园区管理服务、企业服务、产业服务三大服务体系，行业库、项目库、园区库、指标库、企业库、资源库、产品库、GIS库八大数据库为基础；实现园区管理、服务效能双提升，助力产业服务转型升级。-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="op-right">-->
    <!--          <div class="circles">-->
    <!--            <span></span>-->
    <!--            <span></span>-->
    <!--            <span></span>-->
    <!--            <span></span>-->
    <!--            <span></span>-->
    <!--            <span></span>-->
    <!--            <span></span>-->
    <!--            <span></span>-->
    <!--          </div>-->
    <!--          <img src="../../assets/front/product/product_bg.png" alt="" />-->
    <!--          <div class="circlebox">-->
    <!--            <p>工业管理平台化</p>-->
    <!--            <p>产业发展生态化</p>-->
    <!--            <p>园区运营联盟化</p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- 产品体系 -->
    <!--    <div class="productsystem">-->
    <!--      <div class="wp">-->
    <!--        <div class="tit-i">-->
    <!--          <h3>产品体系</h3>-->
    <!--          <h5>-->
    <!--            Product-->
    <!--            <span>System</span>-->
    <!--          </h5>-->
    <!--        </div>-->
    <!--        <div class="ps-wrap">-->
    <!--          <ul class="psw-list clearfix">-->
    <!--            <li @click="goPath(1)">-->
    <!--              <h3>-->
    <!--                <span>园区运营</span>-->
    <!--                <span>智慧云平台</span>-->
    <!--              </h3>-->
    <!--              <div class="pswl-bottom">-->
    <!--                <p>打破园区“信息孤岛”</p>-->
    <!--                <p>扶优扶强心中有“数”</p>-->
    <!--                <p>全域园区协同运营</p>-->
    <!--              </div>-->
    <!--            </li>-->
    <!--            <li @click="goPath(2)">-->
    <!--              <h3>-->
    <!--                <span>工业管理</span>-->
    <!--                <span>智慧云平台</span>-->
    <!--              </h3>-->
    <!--              <div class="pswl-bottom">-->
    <!--                <p>工业家底一图全知晓</p>-->
    <!--                <p>工业运行状态全感知</p>-->
    <!--                <p>工业管理运筹无遗策</p>-->
    <!--              </div>-->
    <!--            </li>-->
    <!--            <li @click="goPath(3)">-->
    <!--              <h3>-->
    <!--                <span>产业指导</span>-->
    <!--                <span>智慧云平台</span>-->
    <!--              </h3>-->
    <!--              <div class="pswl-bottom">-->
    <!--                <p>产业现状看得清</p>-->
    <!--                <p>产业指导更科学</p>-->
    <!--                <p>产业发展能预见</p>-->
    <!--              </div>-->
    <!--            </li>-->
    <!--            <li @click="goPath(4)">-->
    <!--              <h3>-->
    <!--                <span>企业服务</span>-->
    <!--                <span>（赣企服）</span>-->
    <!--              </h3>-->
    <!--              <div class="pswl-bottom">-->
    <!--                <p>大数据精准分析市场需求导向</p>-->
    <!--                <p>互联网收集整合生产配套资源</p>-->
    <!--                <p>物联网链接产能实现生产协同</p>-->
    <!--              </div>-->
    <!--            </li>-->
    <!--          </ul>-->
    <!--          <div class="psw-directions">-->
    <!--            <p>-->
    <!--              <img src="../../assets/front/headernew/updown.png" alt="" />-->
    <!--            </p>-->
    <!--            <p>-->
    <!--              <img src="../../assets/front/headernew/updown.png" alt="" />-->
    <!--            </p>-->
    <!--            <p>-->
    <!--              <img src="../../assets/front/headernew/updown.png" alt="" />-->
    <!--            </p>-->
    <!--            <p>-->
    <!--              <img src="../../assets/front/headernew/updown.png" alt="" />-->
    <!--            </p>-->
    <!--          </div>-->
    <!--          <div class="psw-bottom">工业大数据应用平台</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- 移动端&微门户 -->
    <div class="wp" id="webmobel">
      <div class="tit-i">
        <h3>移动端&微门户</h3>
        <h5>Mobile terminal &amp; Micro portal</h5>
      </div>
      <div class="about-info">
        <p>
          在移动互联时代，我们得出“简单使用，精彩体验”的移动应用开发理念，创造属于无线时代的精彩互联。
        </p>
      </div>
      <!-- <div class="c"></div> -->
      <div class="web-con pr" style="position: relative">
        <ul class="ul-tab TAB_CLICK_SLIDE" id=".tab-show">
          <li :class="{ hover: tab1 === 0 }" @click="switchSwiper(0)">
            <a href="javascript:void(0);">
              <h3>移动办公</h3>
              <span>Mobile officing;</span>
            </a>
          </li>
          <li :class="{ hover: tab1 === 1 }" @click="switchSwiper(1)">
            <a href="javascript:void(0);">
              <h3>微网站</h3>
              <span>Micro website</span>
            </a>
          </li>
          <li :class="{ hover: tab1 === 2 }" @click="switchSwiper(2)">
            <a href="javascript:void(0);">
              <h3>微信应用开发</h3>
              <span>WeChat application</span>
            </a>
          </li>
        </ul>
        <div class="shou">
          <img src="../../assets/front/shou.png" alt="" />
        </div>
        <div class="tab-con-box tabitems">
          <div class="tab-show" v-show="tab1 === 0">
            <div class="tab-con">
              <div class="txt animated fadeInDown">
                <p>
                  移动办公主要面向园区日常运作和管理，通过智能手机，让园区的管理者可以突破时间和空间的限制来进行办公，有效提高工作效率，同时能轻松的处理紧急事务。
                </p>
                <ul class="ul-icon">
                  <li>
                    <b></b>
                    <span>提高办公效率</span>
                  </li>
                  <li>
                    <b></b>
                    <span>园区事务高效管理</span>
                  </li>
                  <li>
                    <b></b>
                    <span>与PC数据同步</span>
                  </li>
                </ul>
                <ul class="ul-icon">
                  <li>
                    <b></b>
                    <span>移动交互体验</span>
                  </li>
                  <li>
                    <b></b>
                    <span>
                      <span> 全兼容 </span>
                      <span> 手机 </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-show" v-show="tab1 === 1">
            <div class="tab-con">
              <div class="txt animated fadeInDown">
                <p>
                  整合企业服务平台，建立能够扩大园区政务公开、对外宣传、招商引资的多元化渠道，从而提高园区对外招商能力和公共服务能力的移动互联网平台
                </p>
                <ul class="ul-icon">
                  <li>
                    <b></b>
                    <span>园区创新服务模式</span>
                  </li>
                  <li>
                    <b></b>
                    <span>最快捷</span>
                  </li>
                  <li>
                    <b></b>
                    <span>最有用</span>
                  </li>
                  <li>
                    <b></b>
                    <span>最方便</span>
                  </li>
                  <li>
                    <b></b>
                    <span>随时、随地、随身访问</span>
                  </li>
                  <li>
                    <b></b>
                    <span>数据同步</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-show" v-show="tab1 === 2">
            <div class="tab-con">
              <div class="txt animated fadeInDown">
                <p>
                  针对园区企业，提供更多智能功能的微信基础服务系统平台。同时可以根据您的需求进行高级功能定制与开发。
                </p>
                <ul class="ul-icon">
                  <li>
                    <b></b>
                    <span>产业地图</span>
                  </li>
                  <li>
                    <b></b>
                    <span>工业共享</span>
                  </li>
                  <li>
                    <b></b>
                    <span>产业电商</span>
                  </li>
                  <li>
                    <b></b>
                    <span>工业融资</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="pic">
          <div class="flexslider">
            <swiper
              ref="mySwiper"
              class="swiper slides"
              :options="swiperOption"
            >
              <swiper-slide>
                <li>
                  <img
                    src="../../assets/front/product/flexslider1.jpg"
                    alt=""
                    class="img"
                  />
                </li>
              </swiper-slide>
              <swiper-slide>
                <li>
                  <img
                    src="../../assets/front/product/flexslider2.jpg"
                    alt=""
                    class="img"
                  />
                </li>
              </swiper-slide>
              <swiper-slide>
                <li>
                  <img
                    src="../../assets/front/product/flexslider3.jpg"
                    alt=""
                    class="img"
                  />
                </li>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>

    <!-- 平台运营服务 -->
    <!--    <div class="platformoperation">-->
    <!--      <div class="wp">-->
    <!--        <div class="moduleTitle">-->
    <!--          <h3>平台运营服务</h3>-->
    <!--          <h5>-->
    <!--            PLATFORM OPERATION-->
    <!--            <span>SERVICE</span>-->
    <!--          </h5>-->
    <!--        </div>-->
    <!--        <ul class="tabbox">-->
    <!--          <li :class="{ active: tab2 === 0 }" @click="tab2 = 0">平台运维</li>-->
    <!--          <li :class="{ active: tab2 === 1 }" @click="tab2 = 1">园区运营</li>-->
    <!--        </ul>-->
    <!--        <div v-show="tab2 === 0" class="platitems">-->
    <!--          <div class="platformwords">-->
    <!--            平台运维的服务目标是保障平台正常、稳定的运行，需要从硬件、软件和数据（平台数据采集与更新服务）三个角度进行保障。-->
    <!--          </div>-->
    <!--          <div class="platformlist">-->
    <!--            <div class="triangle">-->
    <!--              <p>数据</p>-->
    <!--              <p>软件</p>-->
    <!--              <p>硬件</p>-->
    <!--            </div>-->
    <!--            <ul>-->
    <!--              <li>-->
    <!--                <h3>各类数据采集</h3>-->
    <!--                <p>-->
    <!--                  专业团队负责各类数据接口的维护，开展产业、企业数据的采集工作，保障平台数据质量和数量。-->
    <!--                </p>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <h3>软件系统维保</h3>-->
    <!--                <p>-->
    <!--                  在合同维保期内为软件使用提供维护和技术支持，协助园区、企业使用者掌握软件使用，开展工作。-->
    <!--                </p>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <h3>硬件系统维保</h3>-->
    <!--                <p>-->
    <!--                  在合同维保期内，负责硬件系统的保修、维护和配件更换，确保硬件平台的正常运行。-->
    <!--                </p>-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div v-show="tab2 === 1" class="platitems">-->
    <!--          <div class="platformwords">-->
    <!--            智慧园区从来不是“建”成的，而是在运营过程中逐渐呈现效果，发挥效能。-->
    <!--          </div>-->
    <!--          <p class="otherwords">-->
    <!--            协助管委会引入优质企业服务资源进入园区，包括但不限于金融服务机构、法律、财务咨询公司、共享环保、共享电工等第三方企业服务。-->
    <!--          </p>-->
    <!--          <ul class="iconslist">-->
    <!--            <li>-->
    <!--              <p>-->
    <!--                <span>企业联动</span>-->
    <!--                <span>协调服务</span>-->
    <!--              </p>-->
    <!--            </li>-->
    <!--            <li>-->
    <!--              <p>-->
    <!--                <span>第三方</span>-->
    <!--                <span>服务机构</span>-->
    <!--                <span>对接运营</span>-->
    <!--              </p>-->
    <!--            </li>-->
    <!--            <li>-->
    <!--              <p>-->
    <!--                <span>信息化</span>-->
    <!--                <span>活动组织</span>-->
    <!--              </p>-->
    <!--            </li>-->
    <!--          </ul>-->
    <!--          <div class="bottomtxt">-->
    <!--            <p>-->
    <!--              在管委会指导下联系园区企业，推动开展产业对接和协同联动，承接一站式办证管理、物业管理协调等工作。-->
    <!--            </p>-->
    <!--            <p>-->
    <!--              组织两化融合等信息化进企业活动，推动机器换人、ERP系统等业务建设和企业技改、专题培训工作的开展。-->
    <!--            </p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- 服务流程展示 -->
    <div class="service" id="servweb">
      <div class="wp">
        <div class="tit-i tit-i-1">
          <h3 style="color: #fff">服务流程展示</h3>
          <h5>service process</h5>
          <em></em>
        </div>
        <div class="about-info">
          <p class="p_btn">
            <span>需求调研</span>
            --
            <span>洽谈合作</span>
            --
            <span>签订协议</span>
            --
            <span>平台制作</span>
            --
            <span>园区培训</span>
            --
            <span>企业培训</span>
            --
            <span>平台上线</span>
          </p>
          <p></p>
          <p>
            <span></span>
          </p>
        </div>
        <div class="processbg">
          <img src="../../assets/front/product/service_process.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 主要产品应用 -->
    <!--    <div class="productApplication">-->
    <!--      <div class="wp">-->
    <!--        <img-->
    <!--          class="wp-bg"-->
    <!--          src="../../assets/product/productApplication_bg.png"-->
    <!--          alt=""-->
    <!--        />-->
    <!--        <div class="painner">-->
    <!--          <img class="bg1" src="../../assets/product/circle1.png" alt="" />-->
    <!--          <img class="bg2" src="../../assets/product/circle2.png" alt="" />-->
    <!--          <div class="patitle">主要产品应用</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
// import ProductBanner from './components/ProductBanner.vue';
// import MobileMicro from './components/MobileMicro.vue';
// import ProductSystem from './components/ProductSystem.vue';

export default {
  // components: { ProductBanner, MobileMicro, ProductSystem },
  data() {
    return {
      tab1: 0,
      tab2: 0,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1,
        observer: true,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        // },
      },

      active: 0,
      applications: [
        '大数据中心',
        '运行指标',
        '项目管理',
        '企业上报',
        '亩产效益',
        '工业考核',
        '精准帮扶',
        '工作走访',
        '安全生产',
        '企业用电',
        '环保监控',
        '惠企政策',
        '共享厂房',
        '公文管理',
        '并联审批',
        '党建管理',
      ],
    };
  },
  methods: {
    switchSwiper(e) {
      this.tab1 = e;
      this.$refs.mySwiper.swiper.slideTo(e, 600, false);
    },
    goPath(index) {
      if (index == 1) {
        window.open('https://www.jx968969.com/XOffice/login/province/yqyylist');
      } else if (index == 2) {
        window.open('https://www.jx968969.com/XOffice/login/province/gygllist');
      } else if (index == 3) {
        window.open('https://www.jx968969.com/XOffice/login/province/cyzdlist');
      } else if (index == 4) {
        window.open('https://www.jx968969.com/springBoot/site/360000');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#bd {
  //padding-top: 90px;
}
#ban-in {
  position: relative;
  background-position: center 0;
  background-repeat: no-repeat;
  /*padding-top: 16%;*/
  background-size: 100% 100%;
  height: 750px;
  background: url(../../assets/front/product/productbanner.png) center center
    no-repeat;
}
#ban-in .ban-bg {
  /* background: url(../../image/front/ban_bg.png) center top no-repeat; */
  height: 54px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 60;
}
.banner-box {
  height: 100%;
  padding-top: 250px;
  animation-name: fadeInUp;
  animation: fadeInUp 0.5s linear;
  h3 {
    font-size: 46px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
  h5 {
    margin-top: 18px;
    font-size: 20px;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 8px;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.pages {
  text-align: center;
  margin-bottom: 105px;
  margin-top: 20px;
}
.pages a {
  display: inline-block;
  width: 41px;
  height: 41px;
  background-color: #edecec;
  border-radius: 3px;
  text-align: center;
  line-height: 41px;
  font-family: Arial;
  font-size: 18px;
  color: #666;
  margin: 0 4px;
}
.pages a:hover,
.pages .page-on {
  background-color: #1071b5;
  color: #fff;
}
/* 产品服务 */
.moduleTitle {
  text-align: center;
  padding: 40px 0;
}
.moduleTitle h3,
.moduleTitleLeft h3 {
  font: 30px/40px 'PingFang SC';
  color: #333;
}
.moduleTitle h5,
.moduleTitleLeft h5 {
  font: 20px/35px 'PingFang SC';
  color: #999;
}
.moduleTitle h5 span,
.moduleTitleLeft h5 span {
  color: #1f73ff;
}
.moduleTitleLeft {
  padding: 40px 0;
}
/* 产品服务 */
.ourproducts {
  height: 420px;
  padding-top: 30px;
}
.ourproducts .wp {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  height: 100%;
}
.ourproducts .wp .op-left {
  width: 40%;
  padding-bottom: 70px;
}
.ourproducts .wp .op-left .opl-intro {
  font: 16px/30px 'PingFang SC';
  color: #999;
}
.ourproducts .wp .op-right {
  height: 100%;
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-flow: row nowrap;
}
.ourproducts .wp .op-right img {
  display: block;
  width: 210px;
}
.ourproducts .wp .op-right .circlebox {
  width: 90px;
  height: 90px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -45px;
}
.ourproducts .wp .op-right .circlebox p {
  width: 70px;
  height: 70px;
  position: absolute;
  border-radius: 50%;
  font: 14px/20px 'PingFang SC';
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  color: #fff;
  margin: 0;
}
.ourproducts .wp .op-right .circlebox p:nth-of-type(1) {
  background-color: #01aaf5;
  top: -290px;
  left: 0;
  box-shadow: 0px 6px 12px rgba(1, 170, 245, 0.3);
  animation: iconAnimation1 10s infinite;
}
.ourproducts .wp .op-right .circlebox p:nth-of-type(3) {
  background-color: #4ac767;
  top: -150px;
  right: 180px;
  box-shadow: 0px 6px 12px rgba(74, 199, 103, 0.3);
  animation: iconAnimation2 11s infinite;
}
.ourproducts .wp .op-right .circlebox p:nth-of-type(2) {
  background-color: #ff9021;
  top: -150px;
  left: 180px;
  box-shadow: 0px 6px 12px rgba(255, 144, 33, 0.3);
  animation: iconAnimation3 12s infinite;
}
@keyframes iconAnimation1 {
  0% {
    top: -290px;
  }
  50% {
    top: -265px;
  }
  100% {
    top: -290px;
  }
}
@keyframes iconAnimation2 {
  0% {
    top: -150px;
  }
  50% {
    top: -125px;
  }
  100% {
    top: -150px;
  }
}
@keyframes iconAnimation3 {
  0% {
    top: -150px;
  }
  50% {
    top: -125px;
  }
  100% {
    top: -150px;
  }
}
.ourproducts .wp .op-right .circles {
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 170px;
  left: 50%;
  margin-left: -50px;
}
.ourproducts .wp .op-right .circles span {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  animation: circleExercise 5s infinite;
  opacity: 0.5;
}
.ourproducts .wp .op-right .circles span:nth-of-type(1) {
  background-color: #01aaf5;
  left: 5px;
  animation-duration: 5s;
}
.ourproducts .wp .op-right .circles span:nth-of-type(2) {
  background-color: #ff9021;
  left: 17px;
  animation-duration: 4s;
}
.ourproducts .wp .op-right .circles span:nth-of-type(3) {
  background-color: #4ac767;
  left: 36px;
  animation-duration: 3s;
}
.ourproducts .wp .op-right .circles span:nth-of-type(4) {
  background-color: #999;
  left: 50px;
  animation-duration: 6s;
}
.ourproducts .wp .op-right .circles span:nth-of-type(5) {
  background-color: #fdb222;
  left: 65px;
  animation-duration: 2s;
}
.ourproducts .wp .op-right .circles span:nth-of-type(6) {
  background-color: #1f73ff;
  left: 25px;
  animation-duration: 2.5s;
}
.ourproducts .wp .op-right .circles span:nth-of-type(7) {
  background-color: #ff9021;
  left: 75px;
  animation-duration: 4.5s;
}
.ourproducts .wp .op-right .circles span:nth-of-type(8) {
  background-color: #ff687c;
  left: 95px;
  animation-duration: 5.5s;
}
.ourproducts .wp .op-right .circles span:nth-of-type(9) {
  background-color: #9b88fc;
  left: 88px;
  animation-duration: 1.5s;
}
@keyframes circleExercise {
  0% {
    bottom: 10px;
    opacity: 0.5;
    width: 6px;
    height: 6px;
  }
  100% {
    bottom: 100px;
    opacity: 0;
    width: 2px;
    height: 2px;
  }
}
.productsystem {
  width: 100%;
  background: url(../../assets/front/headernew/product_bg.png) center center
    no-repeat;
  background-size: 100% 100%;
  padding-bottom: 50px;
}
.productsystem .psd-titles {
  position: absolute;
  top: 10px;
  left: 0;
}
.productsystem .psd-titles p {
  width: 268px;
  height: 76px;
  float: left;
  margin-right: 21px;
  font: 22px/25px 'PingFang SC';
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.productsystem .psd-titles p:last-of-type {
  margin-right: 0;
}
.productsystem .ps-content {
  position: relative;
  margin-top: -20px;
}
.productsystem .ps-direction {
  overflow: hidden;
  position: relative;
  height: 278px;
}
.productsystem .ps-direction img {
  width: 322px;
  height: 291px;
  position: absolute;
  top: 0;
  left: -12px;
}
/* .productsystem .ps-direction img:hover{ box-shadow: 0px 4px 15px 0px rgba(31, 156, 255, .34);} */
.productsystem .ps-direction img:nth-of-type(2) {
  left: 279px;
}
.productsystem .ps-direction img:nth-of-type(3) {
  left: 569px;
}
.productsystem .ps-direction img:nth-of-type(4) {
  left: 856px;
}
.productsystem .ps-list {
  position: absolute;
  top: 86px;
  left: 0;
}
.productsystem .ps-list li {
  float: left;
  width: 268px;
  margin-right: 21px;
  cursor: pointer;
}
.productsystem .ps-list li:last-of-type {
  margin-right: 0;
}
.productsystem .ps-list li .list-inner {
  width: 100%;
  height: 192px;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
}
.productsystem .ps-list li .list-inner p {
  font: 16px/42px 'PingFang SC';
  color: #666;
}
.productsystem .ps-list li .list-down {
  position: relative;
  width: 10px;
  height: 25px;
  background-color: #fff;
  background-color: #d0e4ff;
  margin: auto;
  overflow: hidden;
}
.productsystem .ps-list li .list-down img {
  position: absolute;
  top: 0;
  left: 0;
}
.productsystem .ps-list li .list-down img:nth-of-type(1) {
  transform: translateY(-19px);
  animation: updown 1s linear infinite;
}
.productsystem .ps-bottom {
  font: 26px/60px 'PingFang SC';
  color: #fff;
  width: 1136px;
  height: 60px;
  text-align: center;
  background-color: #0068db;
  margin-top: 25px;
}
@keyframes updown {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(25px);
  }
}
.productsystem .productlist {
}
.productsystem .productlist li {
  width: 243px;
  height: 300px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  transition: all 0.2s;
  float: left;
  margin-right: 65px;
}
.productsystem .productlist li:last-of-type {
  margin-right: 0;
}
.productsystem .productlist li:hover {
  transform: translateY(-20px);
  transition: all 0.5s;
}
.productsystem .productlist li a {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  height: 100%;
}
.productsystem .productlist li .pl-top {
  width: 100%;
  height: 140px;
  border-bottom: 1px solid #e2e3e8;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
}
.productsystem .productlist li .pl-top img {
  display: block;
  width: 50px;
  height: 50px;
}
.productsystem .productlist li .pl-top p {
  font: 20px/30px 'microsoft yahei';
  color: #333;
  margin-top: 5px;
}
.productsystem .productlist li .pl-bottom {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
}
.productsystem .productlist li .pl-bottom span {
  font: 14px/20px 'microsoft yahei';
  color: #666;
}
.productsystem .productlist li .pl-bottom span:nth-of-type(2),
.productsystem .productlist li .pl-bottom span:nth-of-type(3) {
  margin-top: 13px;
}

.productsystem .ps-wrap .psw-list {
}
.productsystem .ps-wrap .psw-list li {
  float: left;
  width: 23.5%;
  height: 280px;
  background-color: #4444;
  margin-right: 2%;
  cursor: pointer;
}
.productsystem .ps-wrap .psw-list li:hover {
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.7);
}
.productsystem .ps-wrap .psw-list li h3 {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  height: 80px;
  background-color: #c00000;
}
.productsystem .ps-wrap .psw-list li h3 span {
  display: block;
  color: #fff;
  font: 20px/30px 'PingFang SC';
}
.productsystem .ps-wrap .psw-list li:last-of-type {
  margin-right: 0;
}
.productsystem .ps-wrap .psw-list li .pswl-bottom {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  height: 200px;
  background-color: #0070c0;
}
.productsystem .ps-wrap .psw-list li .pswl-bottom p {
  font: 16px/42px 'PingFang SC';
  color: #fff;
  margin: 0;
}
.productsystem .psw-directions {
}
.productsystem .psw-directions p {
  margin: 0;
  float: left;
  width: 23.5%;
  height: 33px;
  margin-right: 2%;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: center;
}
.productsystem .psw-directions p:last-of-type {
  margin-right: 0;
}
.productsystem .psw-bottom {
  font: 26px/60px 'PingFang SC';
  display: flex;
  justify-content: center;
  color: #fff;
  width: 100%;
  height: 60px;
  text-align: center;
  background-color: #002060;
  margin-top: 33px;
}
.about-info {
  text-align: left;
  font-size: 14px;
  line-height: 34px;
  color: #666;
  padding-bottom: 50px;
  p {
    margin: 0;
  }
}
.ul-tab {
  width: 193px;
  float: left;
  //background: url(../../image/front/line3.png) repeat-y 183px top;
  margin-right: 80px;
  padding-top: 45px;
}
.ul-tab li {
  margin-bottom: 50px;
}
.ul-tab li a {
  color: #666;
}
.ul-tab li h3 {
  font-size: 20px;
  //background: url(../../image/front/circle.png) right center no-repeat;
}
.ul-tab li span {
  display: block;
  font-size: 14px;
  color: #999;
}
.ul-tab li.hover h3,
.ul-tab li a:hover h3 {
  //background-image: url(../../image/front/circle1.png);
  color: #1071b5;
}
.ul-tab li.hover span,
.ul-tab li a:hover span {
  color: #1071b5;
}
.web-con .shou {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.web-con .pic {
  float: right;
  position: relative;
  width: 139px;
  height: 280px;
  overflow: hidden;
  text-align: center;
  margin-top: 50px;
  margin-right: 40px;
}
.tab-con .txt {
  /*width: 66%;*/
  width: 84%;
}
.tab-con .pic {
  float: right;
  position: relative;
  width: 218px;
  text-align: center;
  margin-top: 50px;
}
.tab-con .pic .img {
  /* position: absolute;
  left: 43px;
  top: 50px; */
  width: 139px;
  z-index: -1;
}
.tab-con-box {
  width: 59%;
  float: left;
}
.tab-con .txt p {
  font-size: 14px;
  line-height: 24px;
  color: #666;
  padding-bottom: 45px;
  margin: 0;
}
.ul-icon {
  /*padding-right: 50px;*/
  overflow: hidden;
}
.ul-icon li {
  width: 94px;
  height: 94px;
  border-radius: 100%;
  background-color: #e4e5e7;
  color: #fff;
  text-align: center;
  float: left;
  font-size: 15px;
  line-height: 22px;
  //padding: 0 17px;
  margin: 0 25px 30px 25px;
}
.tabitems .ul-icon li {
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height:94px;  */
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation: fadeInDown 1s linear;
}
.tabitems .ul-icon:nth-of-type(1) li:nth-of-type(1) {
  background-color: #01aaf5;
}
.tabitems .ul-icon:nth-of-type(1) li:nth-of-type(2) {
  background-color: #ff9021;
}
.tabitems .ul-icon:nth-of-type(1) li:nth-of-type(3) {
  background-color: #4ac767;
}
.tabitems .ul-icon:nth-of-type(1) li:nth-of-type(4) {
  background-color: #999;
}
.tabitems .ul-icon:nth-of-type(1) li:nth-of-type(5) {
  background-color: #fdb222;
}
.tabitems .ul-icon:nth-of-type(1) li:nth-of-type(6) {
  background-color: #1f73ff;
}
.tabitems .ul-icon:nth-of-type(1) li:nth-of-type(7) {
  background-color: #ff9021;
}
.tabitems .ul-icon:nth-of-type(1) li:nth-of-type(7) {
  background-color: #ff687c;
}
.tabitems .ul-icon:nth-of-type(1) li:nth-of-type(8) {
  background-color: #9b88fc;
}
.tabitems .ul-icon:nth-of-type(2) li:nth-of-type(1) {
  background-color: #fdb222;
}
.tabitems .ul-icon:nth-of-type(2) li:nth-of-type(2) {
  background-color: #1f73ff;
}
.tabitems .ul-icon:nth-of-type(2) li:nth-of-type(3) {
  background-color: #ff9021;
}
.tabitems .ul-icon:nth-of-type(2) li:nth-of-type(4) {
  background-color: #ff687c;
}
.tabitems .ul-icon:nth-of-type(2) li:nth-of-type(5) {
  background-color: #9b88fc;
}
.ul-icon li b,
.ul-icon li span {
  /* display: inline-block; */
  vertical-align: middle;
}
.ul-icon li span {
  line-height: 22px;
}
/* .tabitems .ul-icon li b,.tabitems .ul-icon li span{

} */
.ul-icon li b {
  width: 0;
  height: 100%;
}
.ul-icon {
  /*padding-right: 50px;*/
  overflow: hidden;
}
.tabitems .ul-icon li {
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height:94px;  */
}
.tab-con .animated {
  opacity: 1;
  filter: alpha(opacity=0);
}
.flexslider .animated {
  opacity: 0;
  filter: alpha(opacity=0);
  opacity: 100\9;
  filter: alpha(opacity=100) \9;
}
.tab-show {
  height: 419px;
  overflow: hidden;
}
.platformoperation {
  padding-bottom: 80px;
}
.platformoperation .platformwords {
  font: 16px/20px 'PingFang SC';
  color: #999;
  text-align: center;
  padding-top: 10px;
}

@-webkit-keyframes fadeInDown1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInDown1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.platitems {
  animation: fadeInDown1 1s linear;
}
.platformoperation .platformlist {
  position: relative;
  height: 340px;
  margin: 50px 0 0;
  p {
    margin: 0;
  }
}
.platformoperation .platformlist ul {
  height: 100%;
}
.platformoperation .platformlist ul li {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}
.platformoperation .platformlist ul li:nth-of-type(1) {
  height: 41%;
  padding-left: 25%;
}
.platformoperation .platformlist ul li:nth-of-type(2) {
  height: 30%;
  padding-left: 32%;
}
.platformoperation .platformlist ul li:nth-of-type(3) {
  height: 29%;
  padding-left: 38%;
}
.platformoperation .platformlist ul li h3 {
  font: 20px/20px 'PingFang SC';
  color: #333;
  margin-bottom: 15px;
}
.platformoperation .platformlist ul li p {
  font: 16px/22px 'PingFang SC';
  color: #999;
}
.platformoperation .platformlist .triangle {
  width: 393px;
  height: 340px;
  background: url(../../assets/product/triangle.png) center top repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.platformoperation .platformlist .triangle p {
  font: 26px/26px 'PingFang SC';
  color: #fff;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.platformoperation .platformlist .triangle p:nth-of-type(1) {
  height: 31%;
  padding-top: 40px;
}
.platformoperation .platformlist .triangle p:nth-of-type(2) {
  height: 30%;
}
.platformoperation .platformlist .triangle p:nth-of-type(3) {
  height: 29%;
}
.platformoperation .otherwords {
  font: 16px/30px 'PingFang SC';
  color: #333;
  width: 422px;
  margin: 25px auto 0;
}
.platformoperation .platitems:nth-of-type(3) {
  //display: none;
}
.platformoperation .iconslist {
  padding: 45px 0 40px;
}
.platformoperation .iconslist {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}
.platformoperation .iconslist li {
  width: 33.333%;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: center;
}
.platformoperation .iconslist li p {
  position: relative;
  width: 160px;
  height: 160px;
  border: 6px solid #ff3177;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  font: 24px/30px 'PingFang SC';
  color: #ff3177;
}
.platformoperation .iconslist li p span {
  display: block;
}
.platformoperation .iconslist li p:before {
  content: '';
  position: absolute;
  bottom: -45px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border: 16px solid #ff3177;
  border-color: #ff3177 transparent transparent transparent;
}
.platformoperation .iconslist li:nth-of-type(2) p {
  border-color: #319fff;
  color: #319fff;
}
.platformoperation .iconslist li:nth-of-type(3) p {
  border-color: #ffb21d;
  color: #ffb21d;
}
.platformoperation .iconslist li:nth-of-type(2) p:before {
  border: 16px solid #319fff;
  border-color: transparent transparent #319fff transparent;
  bottom: 160px;
}
.platformoperation .iconslist li:nth-of-type(3) p:before {
  border-top-color: #ffb21d;
}
.platformoperation .iconslist li:nth-of-type(1) p:after,
.platformoperation .iconslist li:nth-of-type(2) p:after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 168px;
  width: 135%;
  height: 10px;
  background-color: #f5f5f5;
}
.platformoperation .bottomtxt {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}
.platformoperation .bottomtxt p {
  width: 35%;
  font: 16px/30px 'PingFang SC';
  color: #333333;
  text-align: left;
}
/* 共用切换 */
.tabbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  margin-bottom: 30px;
}
.tabbox li {
  position: relative;
  font: 20px/28px 'PingFang SC';
  color: #333;
  cursor: pointer;
}
.tabbox li:before {
  content: '';
  position: absolute;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: transparent;
}
.tabbox li.active,
.tabbox li:hover {
  color: #1f73ff;
}
.tabbox li.active:before,
.tabbox li:hover:before {
  background-color: #1f73ff;
}
.tabbox li:nth-of-type(1) {
  margin-right: 180px;
}
#webmobel {
  height: 700px;
}
.service {
  background: url(../../assets/product/process_bg.png) center top no-repeat;
  background-size: 100% 100%;
  /* padding-top: 25px; */
  /* padding-bottom: 63px; */
}

.about-info {
  text-align: left;
  font-size: 14px;
  line-height: 34px;
  color: #666;
  padding-bottom: 50px;
}
.p_btn {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.p_btn span {
  border: 1px solid #dee0e6;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 5px 10px;
}

.process .pad {
  height: 420px;
}
/* 服务流程展示 */
.service .processbg {
  width: 100%;
  padding-bottom: 50px;
}
.service .processbg img {
  width: 100%;
}
/* 主要产品应用 */
.productApplication {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 750px;
  background: url(../../assets/product/applybg.png) center top repeat;
  background-size: 100% 100%;
}
.productApplication .wp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.productApplication .wp .wp-bg {
  position: absolute;
  top: 65px;
  left: 69px;
  width: 1042px;
  height: 607px;
}
.productApplication .painner {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.productApplication .painner .bg1 {
  width: 160px;
  height: 160px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -80px;
  margin-top: -80px;
  animation: turn 14s linear infinite;
}
.productApplication .painner .bg2 {
  width: 100%;
  height: 100%;
  animation: turn 7s linear infinite;
}
@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.productApplication .painner p {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
  font: 14px/20px 'PingFang SC';
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: #fff;
  box-shadow: 0px 6px 12px rgba(1, 170, 245, 0.3);
}
.productApplication .painner .patitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font: 23px/26px 'PingFang SC';
  color: #1f73ff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.productApplication .painner .centerbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.productApplication .painner p:nth-of-type(1) {
  background-color: #30bc8f;
  top: -220px;
  left: 0;
}
.productApplication .painner p:nth-of-type(2) {
  background-color: #53a8fe;
  top: -180px;
  left: 110px;
}
.productApplication .painner p:nth-of-type(3) {
  background-color: #ff687c;
  top: -100px;
  left: 190px;
}
.productApplication .painner p:nth-of-type(4) {
  background-color: #9b88fc;
  top: 0;
  right: -220px;
}
.productApplication .painner p:nth-of-type(5) {
  background-color: #53a8fe;
  top: 100px;
  left: 190px;
}
.productApplication .painner p:nth-of-type(6) {
  background-color: #fdb222;
  top: 180px;
  left: 110px;
}
.productApplication .painner p:nth-of-type(7) {
  background-color: #ff687c;
  top: 220px;
  left: 0;
}
.productApplication .painner p:nth-of-type(8) {
  background-color: #53a8fe;
  top: 180px;
  right: 110px;
}
.productApplication .painner p:nth-of-type(9) {
  background-color: #9b88fc;
  top: 100px;
  right: 190px;
}
.productApplication .painner p:nth-of-type(10) {
  background-color: #fdb222;
  top: 0;
  left: -220px;
}
.productApplication .painner p:nth-of-type(11) {
  background-color: #9b88fc;
  top: -100px;
  right: 190px;
}
.productApplication .painner p:nth-of-type(12) {
  background-color: #fdb222;
  top: -180px;
  right: 110px;
}
.productApplication .painner p:nth-of-type(13) {
  background-color: #9b88fc;
  top: -287px;
  left: 160px;
}
.productApplication .painner p:nth-of-type(14) {
  background-color: #9b88fc;
  top: -193px;
  left: 331px;
}
.productApplication .painner p:nth-of-type(15) {
  background-color: #ff687c;
  top: -193px;
  left: 331px;
}
.productApplication .painner p:nth-of-type(16) {
  background-color: #ff687c;
  top: 0;
  left: 400px;
}
.productApplication .painner p:nth-of-type(17) {
  background-color: #53a8fe;
  top: 177px;
  left: 352px;
}
.productApplication .painner p:nth-of-type(18) {
  background-color: #9b88fc;
  top: -287px;
  right: 160px;
}
.productApplication .painner p:nth-of-type(19) {
  background-color: #30bc8f;
  top: -193px;
  right: 331px;
}
.productApplication .painner p:nth-of-type(20) {
  background-color: #53a8fe;
  top: -33px;
  right: 400px;
}
.productApplication .painner p:nth-of-type(21) {
  background-color: #9b88fc;
  top: 126px;
  right: 373px;
}
.productApplication .painner p:nth-of-type(22) {
  background-color: #30bc8f;
  top: 248px;
  right: 263px;
}

.myProducts {
  background: #f8f9fe;
  padding-bottom: 70px;
}
.myProducts-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.myProducts-card-item {
  position: relative;
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
  width: 29%;
  margin-bottom: 25px;
}
.myProducts-card-item i {
  display: block;
  position: absolute;
  overflow: hidden;
  background-image: url(../../assets/front/home/border.svg);
  background-repeat: no-repeat;
  transition: all 0.5s;
  width: 0;
  height: 0;
  opacity: 0;
}

.myProducts-card-item i.t {
  width: 0;
  height: 0;
  overflow: hidden;
  background-position: 0 top;
  background-size: 100%;
  top: 0;
  left: 0;
}

.myProducts-card-item:hover i.t {
  width: 100%;
  height: 3px;
}

.myProducts-card-item i.r {
  width: 0;
  height: 0;
  background-position: right 0;
  background-size: auto 100%;
  right: 0;
  top: 0;
}

.myProducts-card-item:hover i.r {
  height: 100%;
  width: 3px;
}

.myProducts-card-item i.b {
  width: 0;
  height: 0;
  background-position: 0 bottom;
  background-size: 100%;
  bottom: 0;
  right: 0;
}

.myProducts-card-item:hover i.b {
  width: 100%;
  height: 3px;
}

.myProducts-card-item i.l {
  width: 0;
  height: 0;
  background-position: left 0;
  background-size: auto 100%;
  left: 0;
  bottom: 0;
}

.myProducts-card-item:hover i.l {
  height: 100%;
  width: 3px;
}

.myProducts-card-item:hover i {
  opacity: 1;
}

.myProducts-card-item-content {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  width: 70%;
  h2 {
    font-size: 20px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  i {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: #999;
    border-radius: 50%;
    opacity: 1;
    margin-right: 8px;
    margin-top: 10px;
    vertical-align: middle;
  }
  .li-text {
    margin-left: 10px;
  }
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      width: 50%;
      margin-bottom: 10px;
      font-size: 16px;
      font-family: PingFang SC-细体, PingFang SC;
      font-weight: 100;
      color: #333333;
    }
  }
}

.customer {
  padding-bottom: 70px;
}
.businesss-card {
  display: flex;
  flex-direction: row;
}

.businesss-card-item {
  position: relative;
  cursor: pointer;
  img {
    height: 400px;
  }
  .businesss-card-item-title {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 92%;
    text-align: center;
    font-size: 26px;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;
    transform: skew(-6.5deg);
    span {
      transform: skew(5deg);
    }
  }
}

.businesss-card-item-img {
  height: 400px;
  overflow: hidden;
  transform: skew(-1deg);
}

.businesss-card-item-mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background: #0045ce;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: skew(-5deg);
  .businesss-card-item-mask-title {
    font-size: 30px;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #ffffff;
    transform: skew(5deg);
  }
  .businesss-card-item-mask-bk {
    height: 2px;
    width: 20px;
    background: #ffffff;
    margin: 16px 0;
    transform: skew(5deg);
  }
  .businesss-card-item-mask-content {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    transform: skew(5deg);
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}

.businesss-card-item:hover {
  .businesss-card-item-title {
    display: none;
  }
  .businesss-card-item-mask {
    animation: show 1s linear;
    opacity: 0.85;
  }
  img {
    transform: scale(1.1);
    transition: all 1s;
  }
}
.businesss-card-item:nth-of-type(2) {
  margin: 0 20px;
}

.projectPosition {
  background: #f8f9fe;
  h5 {
    font-size: 26px;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #333333;
    margin-top: 20px;
  }
  .projectPosition-box {
    display: flex;
    flex-direction: row;
    padding: 0 60px;
    padding-bottom: 60px;
    min-height: 500px;
  }
  .projectPosition-box-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .projectPosition-box-center {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .projectPosition-box-card {
    display: flex;
    flex-direction: row;
  }
  .projectPosition-box-card-content {
    margin-left: 20px;
    p {
      font-size: 20px;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #333333;
    }
    h1 {
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    h2 {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #328bff;
    }
    ul {
      li {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-family: PingFang SC-细体, PingFang SC;
        font-weight: normal;
        color: #333333;
        font-weight: 100;
        i {
          display: inline-block;
          width: 4px;
          height: 4px;
          background: #0066db;
          border-radius: 50%;
          opacity: 1;
          margin-right: 8px;
          margin-top: 8px;
          vertical-align: middle;
        }
      }
    }
  }
}

.serviceSystem {
  height: 960px;
  background: url(../../assets/front/product/ssbg.png) center center no-repeat;
  background-size: 100%;
  .wp {
    height: 100%;
  }
  h3 {
    color: #ffffff;
  }
  .serviceSystem-box {
    position: relative;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    img {
      width: 239px;
    }
  }
  .orgdot {
    background: rgba(255, 96, 0, 0.7) !important;
    box-shadow: inset 0px 5px 10px 1px rgba(255, 255, 255, 0.81) !important;
    border: 1px solid #ffd606 !important;
  }
  .serviceSystem-box-dot {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    background: rgba(0, 44, 120, 0.49);
    box-shadow: inset 0px 5px 10px 1px rgba(255, 255, 255, 0.81);
    opacity: 1;
    border: 1px solid #06e6ff;
    border-radius: 50%;
    p {
      margin: 0;
    }
    .serviceSystem-box-dot-pop {
      display: none;
      position: absolute;
      left: -250px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      padding: 5px 8px;
      width: 220px;
    }
  }
  .serviceSystem-box-dot:hover {
    .serviceSystem-box-dot-pop {
      display: flex;
    }
  }
  .dot1 {
    height: 93px;
    width: 93px;
  }
  .dot2 {
    height: 108px;
    width: 108px;
  }
  .dot3 {
    height: 80px;
    width: 80px;
  }
  .dot4 {
    height: 140px;
    width: 140px;
  }
  .dot5 {
    height: 122px;
    width: 122px;
  }
  .dot-ani1 {
    animation: dot1 10s infinite;
  }
  .dot-ani2 {
    animation: dot2 15s infinite;
  }
  .dot-ani3 {
    animation: dot3 10s infinite;
  }
  .dot-ani4 {
    animation: dot4 10s infinite;
  }
  .dot-ani5 {
    animation: dot5 10s infinite;
  }
  .dot-ani6 {
    animation: dot6 10s infinite;
  }
  .dot-ani7 {
    animation: dot7 10s infinite;
  }
  .dot-ani8 {
    animation: dot8 10s infinite;
  }
  .dot-ani9 {
    animation: dot9 10s infinite;
  }
}
@keyframes dot1 {
  0% {
    top: -10px;
  }
  50% {
    top: 0;
  }
  100% {
    top: 10px;
  }
}
@keyframes dot2 {
  0% {
    top: 40px;
  }
  50% {
    top: 50px;
  }
  100% {
    top: 60px;
  }
}
@keyframes dot3 {
  0% {
    top: 180px;
  }
  50% {
    top: 170px;
  }
  100% {
    top: 160px;
  }
}
@keyframes dot4 {
  0% {
    top: 210px;
  }
  50% {
    top: 200px;
  }
  100% {
    top: 190px;
  }
}
@keyframes dot5 {
  0% {
    top: 210px;
  }
  50% {
    top: 220px;
  }
  100% {
    top: 230px;
  }
}
@keyframes dot6 {
  0% {
    top: 240px;
  }
  50% {
    top: 250px;
  }
  100% {
    top: 260px;
  }
}
@keyframes dot7 {
  0% {
    top: 310px;
  }
  50% {
    top: 300px;
  }
  100% {
    top: 290px;
  }
}
@keyframes dot8 {
  0% {
    bottom: 140px;
  }
  50% {
    bottom: 130px;
  }
  100% {
    bottom: 120px;
  }
}
@keyframes dot9 {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 20px;
  }
}
</style>
